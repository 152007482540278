.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.App-header {
  text-align: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.input-fields {
  width: 250px;
  margin: 10px;
  padding: 8px;
}


.button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.file-button,
.submit-button,
.signup-button,
.signin-button,
.db-input-button,
.db-data-button,
.db-update-button,
.db-delete-button {
  width: 150px;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.file-button {
  background-color: #ff9800;
}

.submit-button {
  background-color: #4caf50;
}

.signup-button {
  background-color: #2196f3;
}

.signin-button {
  background-color: #9c27b0;
}

.db-input-button {
  background-color: #ffc107;
}

.db-data-button {
  background-color: #3f51b5;
}

.db-update-button {
  background-color: #e91e63;
}

.db-delete-button {
  background-color: #607d8b;
}